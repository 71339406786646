var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "create-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.newUser,
                inline: true,
                rules: _vm.rules,
                disabled: _vm.creating,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      id: "new-name",
                      name: "new-name",
                      autocomplete: "new-name",
                      placeholder: "Name",
                    },
                    model: {
                      value: _vm.newUser.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.newUser, "name", $$v)
                      },
                      expression: "newUser.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "email" } },
                [
                  _c("el-input", {
                    attrs: {
                      id: "new-email",
                      name: "new-email",
                      autocomplete: "new-email",
                      placeholder: "Email",
                    },
                    model: {
                      value: _vm.newUser.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.newUser, "email", $$v)
                      },
                      expression: "newUser.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.newUserValid,
                    type: "primary",
                    icon: _vm.creating ? "el-icon-loading" : null,
                  },
                  on: { click: _vm.handleCreateUser },
                },
                [_vm._v(" Create user ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("admin-user-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          "element-loading-text": "Loading users",
          users: _vm.filteredUsers,
          scroll: true,
          css: { height: "calc(100vh - 200px)" },
        },
        on: {
          "delete-user": _vm.handleDeleteUser,
          "suspend-user": _vm.handleSuspendUser,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }