<template>
    <div class="user-row" @click="$emit('click')">
        <div class="name">{{ name }}</div>
        <div class="email">{{ email }}</div>

        <div class="comments">
            <el-checkbox
                v-model="canManageComments"
                @change="handleToggleCanManageComments"
            />
        </div>

        <div class="password">
            <el-button
                :icon="resetting ? 'el-icon-loading' : 'el-icon-message'"
                size="mini"
                :disabled="working || is_suspended"
                @click.stop="handleResetPassword"
            />
            <el-popover
                v-model="showPasswordPrompt"
                class="set-password"
                :width="200"
            >
                <el-input
                    v-model="newPassword"
                    type="password"
                    placeholder="New password"
                    size="mini"
                />
                <el-button
                    type="primary"
                    size="mini"
                    :disabled="!newPassword"
                    @click="handleSetPassword"
                >
                    Set password
                </el-button>
                <el-button
                    slot="reference"
                    :icon="setting ? 'el-icon-loading' : 'el-icon-edit'"
                    size="mini"
                    :disabled="working || is_suspended"
                    @click.stop
                />
            </el-popover>
        </div>

        <div class="status">
            <el-tag v-if="is_suspended" type="danger">Suspended</el-tag>
            <el-tag v-else type="info">Active</el-tag>
        </div>

        <div class="suspend">
            <el-button
                v-if="is_suspended"
                type="success"
                :icon="suspending ? 'el-icon-loading' : 'el-icon-video-play'"
                size="mini"
                class="suspend-button"
                :disabled="working"
                @click.stop="handleSuspendUser(false)"
            />
            <el-button
                v-else
                type="warning"
                :icon="suspending ? 'el-icon-loading' : 'el-icon-video-pause'"
                size="mini"
                class="suspend-button"
                :disabled="working"
                @click.stop="handleSuspendUser(true)"
            />
        </div>

        <div class="delete">
            <el-popconfirm
                title="Are you sure you want to delete this user?"
                icon="el-icon-warning"
                icon-color="red"
                confirm-button-text="Delete"
                cancel-button-text="Cancel"
                @confirm="handleDeleteUser"
            >
                <el-button
                    slot="reference"
                    type="danger"
                    :icon="deleting ? 'el-icon-loading' : 'el-icon-delete'"
                    size="mini"
                    :disabled="working"
                    @click.stop
                />
            </el-popconfirm>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import axios from 'axios';

export default {
    name: 'external-user-row',
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            deleting: false,
            suspending: false,
            resetting: false,
            setting: false,
            showPasswordPrompt: false,
            newPassword: null,
            canManageComments: this.user.can_manage_comments,
        };
    },
    computed: {
        working() {
            return (
                this.deleting ||
                this.suspending ||
                this.resetting ||
                this.setting
            );
        },
        is_suspended() {
            return this.user && this.user.status === 'suspended';
        },
        name() {
            return this.user.name;
        },
        email() {
            return this.user.email;
        },
    },
    methods: {
        async handleToggleCanManageComments(val) {
            this.canManageComments = val;
            await this.$fire.doc(`users/${this.user.id}`).update({
                can_manage_comments: val,
            });
        },
        async handleDeleteUser() {
            this.deleting = true;
            this.$emit('delete', this.user.id);
        },
        async handleSuspendUser(suspended) {
            this.suspending = true;
            this.$emit('suspend', this.user.id, suspended);
        },
        async handleResetPassword() {
            this.resetting = true;
            try {
                const token = await firebase.auth().currentUser.getIdToken();
                const data = {id: this.user.id};
                await axios.post(
                    `${process.env.VUE_APP_BASE_URI}/user/reset-password-admin`,
                    data,
                    {
                        headers: {Authorization: `Bearer ${token}`},
                    }
                );
                this.$notify.success({
                    title: 'Success',
                    message:
                        'A password reset email has been sent to this user.',
                });
            } catch (e) {
                this.$notify.error({
                    title: 'Error',
                    message: 'Error resetting password',
                });
            }
            this.resetting = false;
        },
        async handleSetPassword() {
            this.showPasswordPrompt = false;
            this.setting = true;
            try {
                const token = await firebase.auth().currentUser.getIdToken();
                const data = {id: this.user.id, password: this.newPassword};
                await axios.post(
                    `${process.env.VUE_APP_BASE_URI}/user/set-password-admin`,
                    data,
                    {
                        headers: {Authorization: `Bearer ${token}`},
                    }
                );
                this.$notify.success({
                    title: 'Success',
                    message: "User's password has been changed.",
                });
                this.newPassword = null;
            } catch (e) {
                this.$notify.error({
                    title: 'Error',
                    message: 'Error setting password',
                });
            }
            this.setting = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.user-row {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f2f5;
    background-color: white;
    transition: background-color 0.125s linear;

    &:hover {
        background-color: #f5f7fa;
    }

    > * {
        &:first-child {
            flex-grow: 1;
        }

        display: flex;
        align-items: center;
        border-right: 1px solid #f0f2f5;
        height: 100%;
        min-width: 100px;
        padding: 10px;
        color: #606266;
        font-size: 14px;
    }

    .name,
    .email {
        flex: 1;
    }

    .comments {
        width: 180px;
        justify-content: center;
    }
    .password {
        width: 120px;
        justify-content: center;
    }
    .status {
        width: 100px;
        justify-content: center;
    }

    .delete,
    .suspend {
        width: 80px;
        justify-content: center;
        padding: 0;
        min-width: 0;

        .suspend-button {
            font-size: 18px;
            padding: 3px 11px;
        }
    }

    &.pending {
        .user-row-name {
            color: #c0c0c0;
        }
    }
}

.el-tag {
    width: 100%;
    text-align: center;
}

.set-password {
    margin-left: 10px;
}
.el-popover {
    .el-input {
        margin-bottom: 5px;
    }

    .el-button {
        width: 100%;
    }
}
</style>
