var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "user-row",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
      _c("div", { staticClass: "email" }, [_vm._v(_vm._s(_vm.email))]),
      _c(
        "div",
        { staticClass: "comments" },
        [
          _c("el-checkbox", {
            on: { change: _vm.handleToggleCanManageComments },
            model: {
              value: _vm.canManageComments,
              callback: function ($$v) {
                _vm.canManageComments = $$v
              },
              expression: "canManageComments",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "password" },
        [
          _c("el-button", {
            attrs: {
              icon: _vm.resetting ? "el-icon-loading" : "el-icon-message",
              size: "mini",
              disabled: _vm.working || _vm.is_suspended,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleResetPassword.apply(null, arguments)
              },
            },
          }),
          _c(
            "el-popover",
            {
              staticClass: "set-password",
              attrs: { width: 200 },
              model: {
                value: _vm.showPasswordPrompt,
                callback: function ($$v) {
                  _vm.showPasswordPrompt = $$v
                },
                expression: "showPasswordPrompt",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "New password",
                  size: "mini",
                },
                model: {
                  value: _vm.newPassword,
                  callback: function ($$v) {
                    _vm.newPassword = $$v
                  },
                  expression: "newPassword",
                },
              }),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: !_vm.newPassword,
                  },
                  on: { click: _vm.handleSetPassword },
                },
                [_vm._v(" Set password ")]
              ),
              _c("el-button", {
                attrs: {
                  slot: "reference",
                  icon: _vm.setting ? "el-icon-loading" : "el-icon-edit",
                  size: "mini",
                  disabled: _vm.working || _vm.is_suspended,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                slot: "reference",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "status" },
        [
          _vm.is_suspended
            ? _c("el-tag", { attrs: { type: "danger" } }, [_vm._v("Suspended")])
            : _c("el-tag", { attrs: { type: "info" } }, [_vm._v("Active")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "suspend" },
        [
          _vm.is_suspended
            ? _c("el-button", {
                staticClass: "suspend-button",
                attrs: {
                  type: "success",
                  icon: _vm.suspending
                    ? "el-icon-loading"
                    : "el-icon-video-play",
                  size: "mini",
                  disabled: _vm.working,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSuspendUser(false)
                  },
                },
              })
            : _c("el-button", {
                staticClass: "suspend-button",
                attrs: {
                  type: "warning",
                  icon: _vm.suspending
                    ? "el-icon-loading"
                    : "el-icon-video-pause",
                  size: "mini",
                  disabled: _vm.working,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSuspendUser(true)
                  },
                },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "delete" },
        [
          _c(
            "el-popconfirm",
            {
              attrs: {
                title: "Are you sure you want to delete this user?",
                icon: "el-icon-warning",
                "icon-color": "red",
                "confirm-button-text": "Delete",
                "cancel-button-text": "Cancel",
              },
              on: { confirm: _vm.handleDeleteUser },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "reference",
                  type: "danger",
                  icon: _vm.deleting ? "el-icon-loading" : "el-icon-delete",
                  size: "mini",
                  disabled: _vm.working,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                slot: "reference",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }