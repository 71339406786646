<template>
    <div class="app-container">
        <div class="create-container">
            <el-form
                ref="form"
                :model="newUser"
                :inline="true"
                :rules="rules"
                :disabled="creating"
            >
                <el-form-item prop="name">
                    <el-input
                        id="new-name"
                        v-model="newUser.name"
                        name="new-name"
                        autocomplete="new-name"
                        placeholder="Name"
                    />
                </el-form-item>
                <el-form-item prop="email">
                    <el-input
                        id="new-email"
                        v-model="newUser.email"
                        name="new-email"
                        autocomplete="new-email"
                        placeholder="Email"
                    />
                </el-form-item>

                <el-button
                    :disabled="!newUserValid"
                    type="primary"
                    :icon="creating ? 'el-icon-loading' : null"
                    @click="handleCreateUser"
                >
                    Create user
                </el-button>
            </el-form>
        </div>

        <admin-user-list
            v-loading="loading"
            element-loading-text="Loading users"
            :users="filteredUsers"
            :scroll="true"
            :css="{height: 'calc(100vh - 200px)'}"
            @delete-user="handleDeleteUser"
            @suspend-user="handleSuspendUser"
        />
    </div>
</template>

<script>
import AdminUserList from '@/views/components/AdminUserList';
import auth from '@/utils/auth';
import axios from 'axios';
import firebase from 'firebase/app';

export default {
    name: 'users',
    components: {
        AdminUserList,
    },
    props: {
        view: {
            type: String,
            default: null,
        },
        initial_search: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            users: [],
            loading: true,
            debouncedSearch: '',
            newUser: {
                name: null,
                email: null,
            },
            creating: false,
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Name is required',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        type: 'email',
                        message: 'Valid email is required',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    computed: {
        filteredUsers() {
            if (!this.users) return [];

            let users = this.users;
            users = this.users.filter((u) => {
                return !u.deleted_at;
            });

            return users.sort((a, b) => {
                if (!a.name) {
                    if (!b.name) {
                        // if neither user is named, sort by email
                        return a.email.localeCompare(b.email);
                    }
                    // named users before pending users
                    return 1;
                }
                if (!b.name) {
                    // named users before pending users
                    return -1;
                }
                // sort by name
                return a.name.localeCompare(b.name);
            });
        },

        newUserValid() {
            return this.newUser.name && this.newUser.email;
        },
    },

    mounted() {
        this.search = this.initial_search;
        this.bindUsers();
    },

    methods: {
        bindUsers() {
            this.loading = true;

            this.$bind(
                'users',
                this.$fire
                    .collection('users')
                    .where('role', '==', auth.ROLE_ADMIN),
                {
                    maxRefDepth: 0,
                }
            );

            this.loading = false;
        },

        async handleSuspendUser(userId, suspended) {
            try {
                await this.suspendUser(userId, suspended);
            } catch (err) {
                console.log('Suspend', err);
                this.$notify.error({
                    title: 'Error',
                    message: 'Error suspending user',
                });
            }
            // force refresh of list
            this.bindUsers();
        },

        async handleDeleteUser(userId) {
            try {
                await this.deleteUser(userId);
            } catch (err) {
                this.$notify.error({
                    title: 'Error',
                    message: 'Error deleting user',
                });
            }
            // force refresh of list
            this.bindUsers();
        },

        async handleCreateUser() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.creating = true;
                    this.createUser()
                        .then((result) => {
                            console.log('Success:', result);
                            this.$notify.success({
                                title: 'Success',
                                message: 'User has been created.',
                            });
                            this.newUser = {
                                name: null,
                                email: null,
                            };
                            this.creating = false;
                        })
                        .catch((err) => {
                            this.creating = false;
                            console.log('Error creating user: ', err);
                            this.$notify.error({
                                title: 'Error',
                                message: 'Error creating user',
                            });
                        });
                }
            });
        },

        async createUser() {
            const token = await firebase.auth().currentUser.getIdToken();
            const data = {
                name: this.newUser.name,
                email: this.newUser.email,
            };
            return axios.post(
                `${process.env.VUE_APP_BASE_URI}/user/create-admin`,
                data,
                {
                    headers: {Authorization: `Bearer ${token}`},
                }
            );
        },

        async deleteUser(id) {
            const token = await firebase.auth().currentUser.getIdToken();
            return axios.delete(
                `${process.env.VUE_APP_BASE_URI}/user/delete-admin`,
                {
                    headers: {Authorization: `Bearer ${token}`},
                    data: {id},
                }
            );
        },
        async suspendUser(id, suspended) {
            const token = await firebase.auth().currentUser.getIdToken();
            return axios.post(
                `${process.env.VUE_APP_BASE_URI}/user/suspend-admin`,
                {id, suspended},
                {
                    headers: {Authorization: `Bearer ${token}`},
                }
            );
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pagination-container {
    background-color: white;
    margin: 0;
    padding: 20px;
    border: 1px solid #ebeef5;
    border-top: 0;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.create-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .el-form {
        width: 100%;
        text-align: right;
    }
    > *:not(:first-child) {
        margin-left: 10px;
    }
}

.full-width {
    width: 100%;
}

.el-tag {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;
}
</style>
