var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-list-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "user-list",
        class: { scroll: _vm.scroll },
        style: _vm.css,
      },
      [
        _vm._m(0),
        _vm.users.length
          ? _c(
              "div",
              { staticClass: "user-list-body" },
              _vm._l(_vm.users, function (user) {
                return _c("admin-user-row", {
                  key: user.id,
                  attrs: { user: user },
                  on: {
                    delete: _vm.handleDeleteUser,
                    suspend: _vm.handleSuspendUser,
                  },
                })
              }),
              1
            )
          : _c("div", { staticClass: "no-users" }, [_vm._v("No data")]),
      ]
    ),
    _vm.scroll
      ? _c("div", { staticClass: "user-list-footer" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("pluralize")(_vm.users.length, " user")) +
              " found "
          ),
        ])
      : _c(
          "div",
          { staticClass: "page-nav" },
          [
            _c("el-button", {
              attrs: { icon: "el-icon-arrow-left", circle: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("prev")
                },
              },
            }),
            _c("el-button", {
              attrs: { icon: "el-icon-arrow-right", circle: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("next")
                },
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-col name" }, [_vm._v("Name")]),
      _c("div", { staticClass: "header-col email" }, [_vm._v("Email")]),
      _c("div", { staticClass: "header-col comments" }, [
        _vm._v("Manage comments"),
      ]),
      _c("div", { staticClass: "header-col password" }, [_vm._v("Password")]),
      _c("div", { staticClass: "header-col status" }, [_vm._v("Status")]),
      _c("div", { staticClass: "header-col suspend" }, [
        _c("i", { staticClass: "el-icon-video-pause" }),
      ]),
      _c("div", { staticClass: "header-col delete" }, [
        _c("i", { staticClass: "el-icon-delete" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }